import axios from 'axios';

// const BASE_URL = 'http://localhost:3000/api';

const apiClient = axios.create({
    headers: {
        // baseURL: BASE_URL,
        // 'Content-type': 'application/x-www-form-urlencoded',
        'Content-type': 'application/json',
    }
});

export default apiClient;