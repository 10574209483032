<template>
  <footer class="footer">
    <div class="footer__inner">
      <div class="footer__inner--info">
        <div class="company-info">
          <div class="logo"></div>
          <p>ⓒ MEVERSE PTE. LTD. All Rights Reserved. 2022.</p>
        </div>
        <div class="link-wrap">
          <v-btn target="_blank" :href="item" v-for="(item, index) in linkArray" :key="index">{{index}}</v-btn>
        </div>
      </div>
      <div class="footer__inner--sns">
        <v-btn target="_blank" :href="item" v-for="(item, index) in snsArray" :key="index" :class="[index]"></v-btn>
      </div>
    </div>
  </footer>
</template>

<script>
export default {
  name: 'AppFooterView',
  data() {
    return {
      snsArray: {
        telegram: 'https://t.me/MEVerse_EN',
        twitter: 'https://twitter.com/MEVerseOfficial',
        medium: 'https://medium.com/meverse',
        linkedin: 'https://www.linkedin.com/company/meverse',
        kakao: 'https://open.kakao.com/o/gDDjvgN',
        facebook: 'https://www.facebook.com/OfficialMEVerse',
      },
      linkArray: {
        'MEVerse.sg': 'https://www.meverse.sg/',
        'MEVerse DEX': 'https://www.meversedex.io/',
        'NFT Marketplace': 'https://www.meversedex.io/marketplace',
        'MEVerse Bridge': 'https://www.meversebridge.io/',
        'MEVerse DEV Docs': 'https://meversedex.gitbook.io/meverse-dev-docs/',
        'MEVerse GameZ Docs': 'https://meversedex.gitbook.io/meverse-gamez/',
      }
    }
  }
}
</script>

<style>

</style>