<template>
  <div>
    <v-sheet
      v-if="load"
      :loading="loading"
    >
      <v-skeleton-loader
        class="mx-auto"
        type="card"
      ></v-skeleton-loader>
    </v-sheet>
    <a v-else target="_blank" :href="info.link" class="medium-grid__item">
      <v-img :src="info.image" class="thumbnail"></v-img>
      <div class="item-info">
        <p class="item-info__title" v-html="info.title"></p>
        <p class="item-info__date">{{ info.date }}</p>
      </div>
    </a>
  </div>
</template>

<script>
export default {
  name: 'MediumGridItem',
  data() {
    return {
    }
  },
  props: {
    info: {
      type: Object,
      default: null
    },
    loading: {
      type: Boolean,
      default: false
    },
    load: {
      type: Boolean,
      default: false
    },
  },
}
</script>

<style>

</style>