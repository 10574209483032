import chainNumber from './chain/chainNumber';
import meverse from './chain/meverse';
import contract from './contract';
const env = process.env.VUE_APP_ENV;
const isProduction = env === 'production' ? true : false;

const chain = [
    isProduction ? meverse.main : meverse.test,
];

export default {
    chainNumber,
    chain,
    contract,
    allowChain: chain.map((network) => network.chainId),
}