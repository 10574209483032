import Web3 from 'web3';
import store from '@/store';
import config from '@/config';
import define from '@/define';

let _web3;
if (window?.ethereum) {
    _web3 = new Web3(window?.ethereum);
} else {
    // console.log("new Web3.providers.HttpProvider('https://rpc.meversemainnet.io'")
    _web3 = new Web3(new Web3.providers.HttpProvider('https://rpc.meversemainnet.io'));
}
export const web3 = _web3

const getMetamaskProvider = (abi, address) => {
    return new web3.eth.Contract(abi, address)
}

const injectProvider = (walletType, abi, address) => {
    return getMetamaskProvider(abi, address);
}

export const injectContract = (abi, address) => {
    const walletType = store.state.chain.chain.type;
    return injectProvider(walletType, abi, address);
};

export const injectContractWithType = (type) => {
    const number = store.state.chain.chain.number ?? config.chainNumber.MEVERSE;
    const { abi, address } = config.contract[number][type];
    const walletType = store.state.chain.chain.type ?? define.METAMASK;
    return injectProvider(walletType, abi, address);
};

export const injectContractWithTypeAndAddress = (type, address) => {
    const number = store.state.chain.chain.number ?? config.chainNumber.MEVERSE;
    const { abi } = config.contract[number][type];
    const walletType = store.state.chain.chain.type ?? define.METAMASK;
    return injectProvider(walletType, abi, address);
};