<template>
  <div class="wrapper">
    <app-bar />
    <dex-info-bar />
    <div class="wrapper__inner">
      <slot />
    </div>
    <app-footer />
  </div>
</template>
<script>
import AppBar from "../common/AppBar"
import AppFooter from '../common/AppFooter';
import DexInfoBar from '../common/DexInfoBar';
export default {
  name: 'default-layout',
  components: {
    AppBar,
    AppFooter,
    DexInfoBar
  },
}
</script>