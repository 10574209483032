<template>
  <div v-if="state" class="info-bar">
    <div @click="goLink" class="info-bar__inner">
      <p class="dex-desc">🚀 Go to MEVerse DEX and get MEV to start playing games!</p>
    </div>
    <v-btn @click="goDexBarClose" class="info-bar__close">닫기</v-btn>
  </div>
</template>

<script>
import { getCookie, setCookie } from '@/utils/cookie';
export default {
  name: 'DexInfoBar',
  data() {
    return {
      state: true
    }
  },
  mounted() {
    this.getCookieState();
  },
  methods: {
    goLink() {
      window.open('https://meversedex.io/swap', '_blank');
    },
    goDexBarClose() {
      this.state = false;
      setCookie('goDex_close', JSON.stringify(this.state), 24);
    },
    getCookieState() {
      const cookie = getCookie('goDex_close');
      if (!cookie) return;
      const state = JSON.parse(cookie);
      if (!state) {
        this.state = false;
      } else {
        this.state = true;
      }
    },
  }
}
</script>

<style>

</style>