<template>
  <div>
    <slot />
  </div>
</template>

<script>
export default {
  name: 'none-layout',
}
</script>