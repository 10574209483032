<template>
  <div class="no-data-wrap">
    <div class="no-data-wrap__inner">
      <img src="@/assets/media/Logo/NoData_info_logo.svg" alt="no data">
      <p class="no-data-wrap__inner--text" v-if="!desc">No results found.</p>
      <p class="no-data-wrap__inner--text" v-else-if="typeof desc == 'string'">{{ desc }}</p>
      <ul v-else class="no-data-wrap__inner--desc">
        <li v-for="(item, index) in desc" :key="index">{{ item }}</li>
      </ul>
    </div>
  </div>
</template>

<script>
export default {
  name: 'NoDataInfo',
  data() {
    return {

    }
  },
  props: [ 'desc' ]
}
</script>

<style>

</style>