<template>
  <div class="main-swiper">
    <div class="content-swiper">
      <div class="swiper-wrapper">
        <div v-for="(item, index) in list" :key="index" class="swiper-slide">
          <div class="cover-gradient"></div>
          <div class="cover-blur" :style="{'background-image': `url(${item.banner_img})`}"></div>
          <div class="content-inner">
            <div class="img-wrap">
              <div class="cover-img" :style="{'background-image': `url(${item.banner_img})`}"></div>
              <div class="swiper-pagination"></div>
            </div>
            <div class="desc-wrap">
              <div class="desc-wrap__desc">
                <p class="desc-wrap__desc--title">{{ item.game_name }}</p>
                <div class="desc-wrap__desc--info">
                  <div v-html="item.game_description"></div>
                </div>
              </div>
              <v-btn @click="openPlayGameModal(item)" class="desc-wrap__btn">Play Now</v-btn>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import Swiper from 'swiper/bundle';
import 'swiper/swiper-bundle.css';
import { getBannerGameListAPI } from '@/api/server/game/index.js';
export default {
  name: 'MainSlideView',
  data() {
    return{
      list: [],
    }
  },
  computed: {
    playerId() {
      return this.$store.state.user.userInfo.playerId;
    },
  },
  async mounted() {
    await this.getList();
    this.render();
  },
  methods: {
    render() {
      return new Swiper(".main-swiper .content-swiper", {
        loop: true,
        effect : 'fade',
        loopAdditionalSlides : 1,
        autoplay: {
          delay: 3000,
          disableOnInteraction: false,
        },
        pagination: {
          el: ".swiper-pagination",
          clickable : true,
        },
      });
    },
    async getList() {
      await getBannerGameListAPI()
      .then(res => {
        this.list = res?.data;
      })
      .catch(e => {
        console.log('e: ', e);
      })
    },
    openPlayGameModal(data) {
      this.$store.dispatch('openPlayGameModal', { data });
    },
  }
}
</script>

<style>

</style>