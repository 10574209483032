export default {
    chainName: 'Meverse Mainnet',
    value: 'MEVERSE',
    text: '미버스 메인 네트워크',
    chainId: '0x1d5e',
    rpcUrls: 'https://rpc.meversemainnet.io',
    scanName: 'Meverse Scan',
    nativeCurrency: {
        name: 'Meverse',
        decimals: 18,
        symbol: 'MEV',
    },
    blockExplorerUrls: 'https://meversescan.io',
    scanUrls: '',
}